import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import DateInput from "src/components/Input/DateInput";
import { ScreenTitle } from "src/components/Typography";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

type Props = {
  onSubmit: (data: StudentPlanMilestoneFormData) => void;
  milestoneType: MilestoneType;
  defaultValues?: Partial<Omit<StudentPlanMilestone, "date"> & { date: Date }>;
  formType: "edit" | "create";
};

const SingleMilestoneForm = ({
  milestoneType,
  onSubmit,
  defaultValues,
  formType,
}: Props) => {
  const template = REQUIRED_MILESTONES.find((m) => m.type === milestoneType);
  if (!template) throw new Error("Could not find template for milestone type");
  if (!template.createInstructions)
    throw new Error(
      "Cannot use this special form template to create a milestone."
    );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<StudentPlanMilestoneFormData>({
    mode: "onTouched",
    defaultValues: defaultValues || {
      title: template.name,
      type: template.type,
    },
  });
  const { t } = useTranslation();
  const [showDateField, setShowDateField] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <ScreenTitle>{template.createInstructions.title}</ScreenTitle>
        <Box>
          <Typography variant="subtitle2" fontWeight="bold" marginBottom={1}>
            {t("Step 1: Schedule the appointment")}
          </Typography>
          <Card variant="outlined">
            <CardContent>
              <Typography>{template.createInstructions.description}</Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => window.open(template.createInstructions!.link)}
                variant={showDateField ? "text" : "contained"}
              >
                {t("Schedule")}
              </Button>
            </CardActions>
          </Card>
        </Box>

        <Stack spacing={1}>
          <Typography variant="subtitle2" fontWeight="bold">
            {t("Step 2: Record the date of the appointment")}
          </Typography>
          {!showDateField && (
            <>
              <Typography marginBottom={1}>
                {t(
                  "Have you already scheduled the appointment using the link above?"
                )}
              </Typography>
              <Button variant="outlined" onClick={() => setShowDateField(true)}>
                {t("Yes! All Done.")}
              </Button>
            </>
          )}
          {showDateField && (
            <Stack spacing={2}>
              <DateInput
                control={control}
                name="date"
                label={t("When is your appointment?")}
                rules={{
                  required: { value: true, message: t("The date is required") },
                }}
                disablePast
              />
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <SubmitButton loading={isSubmitting} size="large">
                  {formType === "edit" ? t("Edit") : t("Confirm")}
                </SubmitButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default SingleMilestoneForm;
