import { addDays, isAfter, isBefore } from "date-fns";
import { StudentPlanMilestone } from "src/types/StudentPlan";

export default function isUpcomingMilestone(
  milestone: StudentPlanMilestone,
  days: number = 14
): boolean {
  const today = new Date();
  const upcomingDate = addDays(today, days);

  return (
    isAfter(new Date(milestone.date), today) &&
    isBefore(new Date(milestone.date), upcomingDate) &&
    !milestone.completedAt
  );
}
