import { ChipProps } from "@mui/material";
import { t } from "i18next";

/**
 * Example preseted study plans
 * Adjust as needed
 */
const PRESET_STUDY_PLANS: {
  title: string;
  description: string;
  weeklyCommitmentHours: number;
  color: ChipProps["color"];
}[] = [
  {
    title: t("Explorer"),
    description: t("I’m still deciding if this training is right for me"),
    weeklyCommitmentHours: 4,
    color: "default",
  },
  {
    title: t("Part-Time"),
    description: t("I’ll participate as much as my time allows"),
    weeklyCommitmentHours: 7,
    color: "info",
  },
  {
    title: t("Finisher"),
    description: t("I’m fully committed to completing ASAP"),
    weeklyCommitmentHours: 15,
    color: "success",
  },
];

export default PRESET_STUDY_PLANS;
