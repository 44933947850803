import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import fetchLessonProgressCount from "src/firebase/fetchLessonProgressCount";
import MilestoneType from "src/types/MilestoneType.enum";
import { UserAccount, UserOnboardingStatus } from "src/types/User";
import useErrorHandler from "src/utils/useErrorHandler";
import useUserMilestones from "src/utils/useUserMilestones";
import { StudentPlanMilestoneFormData } from "../ClientScreen/StudentPlanTab/StudentPlanMilestoneForm";

export const trainingLabels = [
  "Application",
  "1st Lesson",
  "Theory Training",
  "Exam Scheduling",
  "Exam Practice",
  "Enrollment",
  "Skills Training",
  "Job Search",
  "On the Job",
] as const;
export type TrainingLabel = typeof trainingLabels[number];

export interface ActionItem<TData> {
  title: string;
  onCreate: (data: TData) => Promise<void>;
}

type TrainingStepResult =
  | {
      trainingLabel: "Exam Scheduling";
      actionItem: ActionItem<StudentPlanMilestoneFormData>;
      milestoneType: MilestoneType.THEORY_EXAM;
    }
  | {
      trainingLabel: Exclude<TrainingLabel, "Exam Scheduling">; // Any label other than "Exam Scheduling"
      actionItem?: never; // Explicitly disallow actionItem
    };

export default function useCurrentTrainingStep(
  user: UserAccount
): TrainingStepResult {
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [videoCount, setVideoCount] = useState<number>(0);
  const [hasScheduledTheoryExam, setHasScheduledTheoryExam] =
    useState<boolean>(false);

  const {
    findMilestone,
    loading: milestonesLoading,
    createMilestone,
  } = useUserMilestones(user);

  useEffect(() => {
    fetchLessonProgressCount(user.uid, clients)
      .then(setVideoCount)
      .catch(errorHandler);
  }, [user, clients, errorHandler]);

  useEffect(() => {
    if (!milestonesLoading) {
      const scheduled = !!findMilestone(MilestoneType.THEORY_EXAM);
      setHasScheduledTheoryExam(scheduled);
    }
  }, [milestonesLoading, findMilestone]);

  if (
    user.employmentStatus === "employed" ||
    user.employmentStatus === "conditional_offer" ||
    user.onboardingStatus === UserOnboardingStatus.EMPLOYED
  ) {
    return { trainingLabel: t("On the Job") };
  }

  if (
    user.employmentStatus ||
    user.enrollmentStatus === "licensed" ||
    user.onboardingStatus === UserOnboardingStatus.LICENSED
  ) {
    return { trainingLabel: "Job Search" };
  }

  if (
    user.enrollmentStatus === "skills_training" ||
    user.enrollmentStatus === "completed_skills_training" ||
    user.onboardingStatus === UserOnboardingStatus.RANGE ||
    user.onboardingStatus === UserOnboardingStatus.GRADUATED
  ) {
    return { trainingLabel: t("Skills Training") };
  }

  if (user.applicationStatus === "enrolled") {
    return { trainingLabel: t("Enrollment") };
  }

  if (videoCount > 20) {
    if (hasScheduledTheoryExam) {
      return { trainingLabel: t("Exam Practice") };
    }
    return {
      trainingLabel: t("Exam Scheduling"),
      actionItem: {
        title: t("Schedule Theory Exam"),
        onCreate: createMilestone,
      },
      milestoneType: MilestoneType.THEORY_EXAM,
    } as TrainingStepResult;
  }

  if (videoCount > 1) {
    return { trainingLabel: t("Theory Training") };
  }

  if (videoCount === 1) {
    return { trainingLabel: t("1st Lesson") };
  }

  if (user.applicationStatus) {
    return { trainingLabel: t("Application") };
  }

  return { trainingLabel: t("Application") }; // Default case
}
