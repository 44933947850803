import { CheckOutlined, RemoveOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import formatQuizQuestionAnswer from "./formatQuizQuestionAnswer";

interface Props {
  index: number;
  onSelect: () => void;
  isSelected?: boolean;
  answer: string;
  showKey?: boolean;
  isCorrectAnswer?: boolean;
}

const DefaultItem = ({
  isSelected,
  index,
  answer,
  onSelect,
}: Pick<Props, "isSelected" | "answer" | "onSelect" | "index">) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: theme.palette.grey[400] }}
      onClick={onSelect}
    >
      <Box display="flex" padding={theme.spacing(4)} alignItems="center">
        <Box
          sx={{
            border: 1,
            borderColor: isSelected
              ? theme.palette.success.main
              : theme.palette.grey[500],
            backgroundColor: isSelected ? theme.palette.success.main : "",
            borderRadius: 1000,
            width: 8,
            height: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            padding: 1,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: isSelected ? "white" : theme.palette.grey[500],
            }}
          >
            {String.fromCharCode(97 + index)}
          </Typography>
        </Box>
        <Box marginLeft={4}>
          <Typography>{answer}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const AnswerKeyItem = ({
  isCorrectAnswer,
  isSelected,
  answer,
}: Pick<Props, "isCorrectAnswer" | "isSelected" | "answer">) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box borderBottom={1} borderColor={theme.palette.grey[400]}>
      <Box display="flex" padding={theme.spacing(4)} alignItems="center">
        <Box
          sx={{
            border: 1,
            borderColor: isCorrectAnswer
              ? theme.palette.success.main
              : theme.palette.error.main,
            backgroundColor: isCorrectAnswer
              ? theme.palette.success.main
              : theme.palette.error.main,
            borderRadius: "50%",
            width: 8,
            height: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            padding: 1,
          }}
        >
          {isCorrectAnswer ? (
            <CheckOutlined sx={{ color: "white" }} />
          ) : (
            <RemoveOutlined sx={{ color: "white" }} />
          )}
        </Box>
        <Stack marginLeft={4}>
          {isCorrectAnswer ? (
            <Typography
              fontSize={14}
              sx={{
                color: theme.palette.success.main,
                textTransform: "uppercase",
              }}
            >
              {t("Correct")}
              {isSelected ? " (Selected)" : ""}
            </Typography>
          ) : (
            <Typography
              fontSize={14}
              sx={{
                color: theme.palette.error.main,
                textTransform: "uppercase",
              }}
            >
              {t("Incorrect")}
              {isSelected ? " (Selected)" : ""}
            </Typography>
          )}
          <Typography>{answer}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const QuizQuestionAnswerItem = ({
  onSelect,
  isSelected,
  index,
  answer,
  showKey,
  isCorrectAnswer,
}: Props) => {
  const formattedAnswer = formatQuizQuestionAnswer(answer);

  if (showKey)
    return (
      <AnswerKeyItem
        isSelected={isSelected}
        answer={formattedAnswer}
        isCorrectAnswer={isCorrectAnswer}
      />
    );

  return (
    <DefaultItem
      onSelect={onSelect}
      index={index}
      answer={formattedAnswer}
      isSelected={isSelected}
    />
  );
};

export default QuizQuestionAnswerItem;
