import {
  DocumentScannerOutlined,
  RocketLaunchOutlined,
  SportsScoreOutlined,
  VideocamOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Drawer,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { addWeeks, format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20, Header32 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import { useCurrentUser } from "src/SessionBoundary";
import { CLP_ID } from "src/utils/constants";
import getRandomThumbnailImage from "src/utils/getRandomThumbnailImage";
import useCurrentTheoryTrainingModule from "src/utils/useCurrentTheoryTrainingModule";
import { trackEvent } from "../OnboardingScreen/amplitude";
import StudyPlanWeekCardList from "../OnboardingScreen/StudyPlanWeekCardList";
import AdditionalExamPracticeCard from "../UnitPage/AdditionalExamPracticeCard";

export default function DashboardTrainingModuleCard() {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { clients } = useAppContext();

  const [viewPlan, setViewPlan] = useState(false);
  const navigate = useNavigate();

  const { currentModuleData, loading } = useCurrentTheoryTrainingModule(
    user,
    clients
  );

  if (!user.theoryTrainingPlan) return <div />;
  if (loading) return <CardSkeleton />;
  if (!currentModuleData) return <div />;

  const { currentModule, trainingStatus, overview, nextItemToDo } =
    currentModuleData;

  const handleClick = () => {
    trackEvent("Dashboard Screen - Clicked on Continue Course");

    // type narrowing
    if (!nextItemToDo) return;
    navigate(nextItemToDo.link);
  };

  const handleReviewClick = () => {
    trackEvent("Dashboard Screen - Clicked on Review Course");
    navigate(`courses/${CLP_ID}`);
  };
  if (trainingStatus === "completed")
    return (
      <Stack spacing={2}>
        <AdditionalExamPracticeCard />
        <Card>
          <CardHeader title={t("Your Lesson Plan")} />
          <CardContent>
            <Typography>
              {t("🎉 You have completed the required lessons and exams!")}
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={handleReviewClick}>
              {t("Review")}
            </Button>
          </CardActions>
        </Card>
      </Stack>
    );

  return (
    <Box>
      <Card
        sx={{
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <CardActionArea onClick={handleClick}>
          <Box display={{ md: "flex" }} justifyContent="space-between">
            <CardContent>
              {/* wrapper for all components */}
              <Stack spacing={2} flex={1}>
                {/* header */}
                <Stack spacing={1}>
                  <Box>
                    <Chip
                      size="small"
                      icon={
                        trainingStatus === "on_time" ? (
                          <SportsScoreOutlined />
                        ) : trainingStatus === "ahead_of_schedule" ? (
                          <RocketLaunchOutlined />
                        ) : (
                          <WarningOutlined />
                        )
                      }
                      label={
                        trainingStatus === "on_time" ||
                        trainingStatus === "ahead_of_schedule"
                          ? t("Due on {{ dueDate }} ", {
                              dueDate: user.theoryTrainingPlan.startDate
                                ? format(
                                    addWeeks(
                                      new Date(
                                        user.theoryTrainingPlan.startDate
                                      ),
                                      currentModule + 1
                                    ),
                                    "MMM d"
                                  )
                                : "this week",
                            })
                          : t("Overdue")
                      }
                      color={
                        trainingStatus === "on_time"
                          ? "info"
                          : trainingStatus === "ahead_of_schedule"
                          ? "success"
                          : "warning"
                      }
                      variant="outlined"
                    />
                  </Box>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Header32>
                      {t(`Week {{ moduleNumber }}`, {
                        moduleNumber: currentModule + 1,
                      })}
                    </Header32>
                    <Header20>
                      {t("out of {{ week }}", {
                        week: user.theoryTrainingPlan.totalWeeks,
                      })}
                    </Header20>
                  </Stack>
                </Stack>
                {/* progress */}
                <Stack spacing={0.5}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      ((overview.completedVideosInModule +
                        overview.completedExamsInModule) /
                        (overview.totalExamsInModule +
                          overview.totalVideosInModule)) *
                      100
                    }
                  />
                  {/* module videos */}
                  <Box>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <VideocamOutlined />
                      <Typography variant="body2" color="text.secondary">
                        {overview.completedVideosInModule}/
                        {overview.totalVideosInModule} {t("lessons")}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <DocumentScannerOutlined />
                      <Typography variant="body2" color="text.secondary">
                        {overview.completedExamsInModule}/
                        {overview.totalExamsInModule} {t("exams")}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>

              {nextItemToDo && nextItemToDo.resource && (
                <Stack marginTop={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("Next Up:")}
                  </Typography>
                  <Header20>{nextItemToDo.resource?.name}</Header20>
                </Stack>
              )}
            </CardContent>
            <CardMedia
              component="img"
              image={getRandomThumbnailImage(currentModule)}
              sx={{
                maxWidth: { xs: "100%", md: 250 }, // Shrink to a max width depending on the device
                height: { xs: 100, md: "auto" }, // Adjust the height based on the device
                objectFit: "cover",
              }}
              alt="Illustration of a week module"
            />
          </Box>
        </CardActionArea>
        <CardActions sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleClick}>
            {t("Continue")}
          </Button>
          <Button onClick={() => setViewPlan(true)}>{t("Review Plan")}</Button>
        </CardActions>
      </Card>
      {/* If late */}
      <Drawer
        open={viewPlan}
        anchor="bottom"
        onClose={() => setViewPlan(false)}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 800, // Default for small screens
            height: "75vh",
            maxHeight: "75vh",
            overflowY: "auto",
            marginY: 4,
            marginX: "auto",
          }}
        >
          <StudyPlanWeekCardList
            studyPlanByWeek={user.theoryTrainingPlan.weeklyPlan}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
