import { useCallback, useEffect, useState } from "react";
import ReactJoyride, { CallBackProps, STATUS } from "react-joyride";
import { useSearchParams } from "react-router-dom";

type UseJoyrideParams = {
  steps: ReactJoyride["props"]["steps"];
  onFinish?: () => void;
};

type UseJoyrideReturn = {
  isTutorialRunning: boolean;
  joyrideSteps: ReactJoyride["props"]["steps"];
  handleJoyrideCallback: ReactJoyride["props"]["callback"];
};

const useJoyrideTutorial = ({
  steps,
  onFinish,
}: UseJoyrideParams): UseJoyrideReturn => {
  const [searchParams] = useSearchParams();
  const [isTutorialRunning, setIsTutorialRunning] = useState(false);

  // Start tutorial if the "showTutorial" query param is true
  useEffect(() => {
    if (searchParams.get("showTutorial") === "true") {
      setIsTutorialRunning(true);
    }
  }, [searchParams]);

  // Callback to handle Joyride state changes
  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data;

      // Stop tutorial on finish or skip
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        setIsTutorialRunning(false);
        if (onFinish) onFinish();
      }
    },
    [onFinish]
  );

  return {
    isTutorialRunning,
    joyrideSteps: steps,
    handleJoyrideCallback,
  };
};

export default useJoyrideTutorial;
