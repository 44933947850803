import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import { QuestionAnswerStatus } from "src/types";

type Props = {
  onCloseIncorrect: () => void;
  status: QuestionAnswerStatus;
};

const QuizAnswerFeedback = ({ status, onCloseIncorrect }: Props) => {
  const anchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: "center",
  };
  switch (status) {
    case "correct":
      return (
        <div>
          <Snackbar open autoHideDuration={6000} anchorOrigin={anchorOrigin}>
            <Alert severity="success">Amazing work! Keep it up.</Alert>
          </Snackbar>
        </div>
      );
    case "incorrect":
      return (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={onCloseIncorrect}
          anchorOrigin={anchorOrigin}
        >
          <Alert severity="error">Give it another shot!</Alert>
        </Snackbar>
      );
    default:
      return <div />;
  }
};

export default QuizAnswerFeedback;
