import { useQuery } from "@apollo/client";
import {
  AccessTime,
  Close,
  Event,
  LightbulbOutlined,
  RocketOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { addDays, addWeeks, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SubmitButton from "src/components/Button/SubmitButton";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { Header20, ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createUserStudyPlan from "src/firebase/createUserStudyPlan";
import { useCurrentUser } from "src/SessionBoundary";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import { trackEvent } from "./amplitude";
import generateStudyPlan, { StudyPlanData } from "./generateStudyPlan";
import { GetRequiredExamsForStudyPlanDocument } from "./GetRequiredExamsForStudyPlan.generated";
import { GetUnitsForStudyPlanDocument } from "./GetUnitsForStudyPlan.generated";
import PRESET_STUDY_PLANS from "./PRESET_STUDY_PLANS";
import StudyPlanWeekCardList from "./StudyPlanWeekCardList";

type Props = {
  courseId: string;
  onSuccess?: () => void;
};

const START_DATE = addDays(new Date(), 1);

export default function TheoryStudyPlanScreen({ courseId, onSuccess }: Props) {
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const navigate = useNavigate();

  const user = useCurrentUser();
  const studyPlanCardRef = useRef<HTMLDivElement>(null); // Add ref for the study

  // We’ll store the user’s selection in state (i.e., the hours they chose)
  const [learningPlan, setLearningPlan] = useState<
    typeof PRESET_STUDY_PLANS[0] | undefined
  >(undefined);
  const [viewDetails, setViewDetails] = useState(false);

  // Load Units and Exams data only once
  const { data: unitsData, loading: unitsLoading } = useQuery(
    GetUnitsForStudyPlanDocument,
    {
      variables: { courseId },
      onError: errorHandler,
    }
  );

  const { data: examsData, loading: examsLoading } = useQuery(
    GetRequiredExamsForStudyPlanDocument,
    {
      variables: { courseId },
      onError: errorHandler,
    }
  );

  // Check if loading
  const loading = unitsLoading || examsLoading;

  // Study plan state
  const [studyPlan, setStudyPlan] = useState<StudyPlanData | undefined>();

  // Scroll to the study plan card
  const handlePlanSelection = (plan: typeof PRESET_STUDY_PLANS[0]) => {
    setLearningPlan(plan);
    setTimeout(() => {
      studyPlanCardRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100); // Delay to ensure the state updates before scrolling
  };

  useEffect(() => {
    if (!learningPlan) return;
    if (unitsData && examsData) {
      const units = unitsData.allUnits || [];
      const exams = examsData.allQuizzes || [];

      const generatedStudyPlan = generateStudyPlan(
        units,
        exams,
        learningPlan,
        START_DATE
      );
      setStudyPlan(generatedStudyPlan);
    }
  }, [unitsData, examsData, learningPlan]);

  const handleSubmit = async () => {
    if (!studyPlan) return;

    trackEvent("Created Theory Training Study Plan", {
      learningPlanType: studyPlan.learningPlan.title,
      weeklyCommitmentHours: studyPlan.learningPlan.weeklyCommitmentHours,
      totalWeeks: studyPlan.totalWeeks,
    });

    await createUserStudyPlan({ user, studyPlan }, clients)
      .then(() => (onSuccess ? onSuccess() : navigate("/")))
      .catch(errorHandler);
  };

  if (loading) {
    return <AppSkeleton />;
  }

  // For plan summary
  const dailyStudyTimeMinutes = learningPlan
    ? (learningPlan.weeklyCommitmentHours * 60) / 7
    : 0; // minutes per day
  const dailyStudyTimeHours = Math.floor(dailyStudyTimeMinutes / 60);
  const dailyStudyTimeRemainingMinutes = Math.round(dailyStudyTimeMinutes % 60);
  const targetEndDate = addWeeks(new Date(), studyPlan?.totalWeeks || 0);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography gutterBottom>
        {t("Which option best describes you?")}
      </Typography>

      {/* Cards for preset study plans */}
      <Stack spacing={2}>
        {PRESET_STUDY_PLANS.map((plan) => (
          <Card
            key={plan.weeklyCommitmentHours}
            variant="outlined"
            sx={{
              borderColor:
                learningPlan?.title === plan.title ? "primary.main" : "",
            }}
          >
            <CardActionArea onClick={() => handlePlanSelection(plan)}>
              <CardContent>
                <Chip
                  icon={<RocketOutlined />}
                  label={plan.title}
                  color={plan.color}
                  variant={
                    learningPlan?.title === plan.title ? "filled" : "outlined"
                  }
                  size="small"
                />
                <Typography marginTop={1}>{plan.description}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>

      {/* Display summary of the chosen plan, if any */}
      {learningPlan && studyPlan ? (
        <Card ref={studyPlanCardRef} variant="outlined" sx={{ marginTop: 3 }}>
          <CardContent>
            <Trans
              i18nKey="<header>Your <underline>{{ learnerType }}</underline> Study Plan</header>"
              values={{ learnerType: learningPlan.title }}
              components={{ underline: <u />, header: <Header20 /> }}
            />
            <List>
              <ListItem>
                <ListItemIcon>
                  <AccessTime />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="I'll study <strong>at least {{ weeklyCommitmentHours }}</strong> hours a week."
                      values={{
                        weeklyCommitmentHours:
                          learningPlan.weeklyCommitmentHours,
                      }}
                      components={{ strong: <strong /> }}
                    />
                  }
                  secondary={
                    <Trans
                      i18nKey="About <strong>{{ dailyStudyTimeHours }}</strong><strong>{{ dailyStudyTimeRemainingMinutes }}</strong> per day."
                      values={{
                        dailyStudyTimeHours:
                          dailyStudyTimeHours > 0
                            ? dailyStudyTimeHours === 1
                              ? `${dailyStudyTimeHours} hr `
                              : `${dailyStudyTimeHours} hrs `
                            : "",
                        dailyStudyTimeRemainingMinutes:
                          dailyStudyTimeRemainingMinutes > 0
                            ? `${dailyStudyTimeRemainingMinutes} min`
                            : "",
                      }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <RocketOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="That means it'll take me <strong>{{ totalWeeks }}</strong> <strong>{{ weekLabel }}</strong> to finish the online course."
                      values={{
                        totalWeeks: studyPlan.totalWeeks,
                        weekLabel:
                          studyPlan.totalWeeks === 1 ? "week" : "weeks",
                      }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Trans
                      i18nKey="I will finish my online training by <strong>{{ endDate }}</strong>"
                      values={{ endDate: format(targetEndDate, "MMM d") }}
                      components={{ strong: <strong /> }}
                    />
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LightbulbOutlined />
                </ListItemIcon>
                <ListItemText color="text.secondary">
                  {t(
                    "Creating a routine is key to building your path to success!"
                  )}
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      ) : (
        learningPlan && <CardSkeleton />
      )}

      <SubmitButton
        sx={{ marginTop: 2 }}
        size="large"
        disabled={!studyPlan}
        loading={false}
        onClick={() => setViewDetails(true)}
      >
        {t("Preview My Plan")}
      </SubmitButton>

      {/* Drawer with the weekly breakdown of the plan */}
      <Drawer
        anchor="bottom"
        open={viewDetails}
        onClose={() => setViewDetails(false)}
      >
        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            height: "75vh",
            maxHeight: "75vh",
            overflowY: "auto",
            marginY: 4,
            marginX: "auto",
          }}
        >
          <Box marginX={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScreenTitle>
                {t("Review your {{ learningType }} plan", {
                  learningType: learningPlan?.title,
                })}
              </ScreenTitle>
              <Box>
                <IconButton onClick={() => setViewDetails(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Stack>
            <Typography
              variant="body2"
              gutterBottom
              marginTop={1}
              marginBottom={4}
              color="text.secondary"
            >
              {t(
                "Here's your online training outline based on your commitment to study {{ weeklyCommitmentHours }}hrs a week.",
                {
                  weeklyCommitmentHours:
                    studyPlan?.learningPlan.weeklyCommitmentHours,
                }
              )}
            </Typography>
          </Box>

          {studyPlan && (
            <StudyPlanWeekCardList studyPlanByWeek={studyPlan?.weeklyPlan} />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2}
            sx={(theme) => ({
              [theme.breakpoints.down("xs")]: {
                position: "fixed",
                bottom: 0,
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 -2px 8px rgba(0,0,0,0.1)",
                paddingY: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            })}
          >
            <Button size="large" onClick={() => setViewDetails(false)}>
              {t("Back")}
            </Button>

            <SubmitButton
              size="large"
              loading={!studyPlan}
              disabled={!studyPlan}
              onClick={handleSubmit}
            >
              {t("Create Plan")}
            </SubmitButton>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}
