import { Stack, Typography, useTheme } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RocketLottie from "src/assets/Lotties/Rocket.json";
import Button from "src/components/Button";

type Props = {
  onMount: () => void;
  onClick: () => void;
  correctAnswers: number;
  numQuestions: number;
};

const QuizGradesSection = ({
  onMount,
  onClick,
  correctAnswers,
  numQuestions,
}: Props) => {
  useEffect(onMount);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      spacing={theme.spacing(2)}
      padding={theme.spacing(4)}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        minHeight: "100vh",
      }}
    >
      <Stack spacing={theme.spacing(2)}>
        <Typography
          textAlign="center"
          color="white"
          fontWeight="bold"
          variant="h4"
        >
          {t("Congrats on finishing another quiz!")} 👏
        </Typography>
        <Typography color="white" textAlign="center" fontWeight="bold">
          {correctAnswers} / {numQuestions} {t("correct")} ·{" "}
          {Math.ceil((correctAnswers / numQuestions) * 100)}%
        </Typography>
      </Stack>
      <Button variant="contained" size="large" onClick={onClick}>
        {t("Done")}
      </Button>
      <Lottie animationData={RocketLottie} style={{ height: 350 }} />
    </Stack>
  );
};

export default QuizGradesSection;
