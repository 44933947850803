import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import Confetti from "src/assets/Lotties/Confetti.json";
import Button from "src/components/Button";
import QuizQuestionCard from "src/components/Card/QuizQuestionCard/QuizQuestionCard";
import { Header } from "src/components/Layout";
import { useSession } from "src/contexts/AuthContext";
import { theme } from "src/theme";
import { QuestionAnswerStatus } from "src/types/Quiz";
import { trackEvent } from "src/utils";
import { GetQuizQuery } from "./GetQuiz.generated";
import IntroSection from "./IntroSection";
import QuizAnswerFeedback from "./QuizAnswerFeedback";
import QuizGradesSection from "./QuizGradesSection";
import QuizNextButton from "./QuizNextButton";

type QuizViewProps = {
  quiz: NonNullable<GetQuizQuery["quiz"]>;
  onFinish: () => void;
};

const QuizView = ({ quiz, onFinish }: QuizViewProps) => {
  const [activeStep, setActiveStep] = useState(-1);
  const [isConfettiOpen, setConfettiOpen] = useState(false);
  const [userAnswers, setUserAnswers] = useState<{
    [questionId: string]: string;
  }>({});
  const [questionStatus, setQuestionStatus] =
    useState<QuestionAnswerStatus>(null);
  const [hasAttemped, setHasAttempted] = useState<{
    [answerId: string]: boolean;
  }>({});
  const [grade, setGrade] = useState(0);

  const session = useSession();

  useEffect(() => {
    if (questionStatus === "correct") {
      setConfettiOpen(true);
    }
  }, [questionStatus]);

  const questions = quiz.questions || [];
  const activeQuestion = questions[activeStep];

  const trackingPayload = {
    quizId: quiz.id,
    quizName: quiz.name,
    numQuestions: questions.length,
  };

  return (
    <Box>
      <Header title={quiz.name} />
      <Collapse
        in={questionStatus === "correct" || questionStatus === "incorrect"}
      >
        <QuizAnswerFeedback
          status={questionStatus}
          onCloseIncorrect={() => setQuestionStatus(null)}
        />
      </Collapse>
      <Box sx={{ backgroundColor: "white" }}>
        <Box
          sx={{
            width: { xs: "48", md: "64" },
            position: "fixed",
            bottom: 0,
            left: { md: "50%" },
            mx: "auto",
            display: !isConfettiOpen ? "none" : "block",
          }}
        >
          <Lottie
            animationData={Confetti}
            autoplay={isConfettiOpen}
            loop={false}
            onComplete={() => setConfettiOpen(false)}
            height="100%"
            width="100%"
            style={{ display: "absolute", bottom: 0 }}
          />
        </Box>
        {activeStep === -1 && (
          <IntroSection
            numQuestions={questions.length}
            onStart={() => setActiveStep((q) => q + 1)}
          />
        )}
        {activeStep > -1 && activeStep < questions.length && (
          <div>
            <QuizQuestionCard
              quizQuestion={questions[activeStep]}
              onSelectAnswer={(questionId, answerId) =>
                setUserAnswers((map) => ({ ...map, [questionId]: answerId }))
              }
              selectedAnswerId={userAnswers[activeQuestion.id]}
              answersAttempted={hasAttemped}
              status={questionStatus}
            />
            <MobileStepper
              sx={{
                borderTop: 1,
                borderColor: theme.palette.grey[300],
                position: "fixed",
                bottom: 0,
                width: "100vw",
              }}
              elevation={1}
              variant="text"
              steps={questions.length}
              position="bottom"
              activeStep={activeStep}
              nextButton={
                <QuizNextButton
                  questionStatus={questionStatus}
                  isDisabled={!userAnswers[activeQuestion.id]}
                  onCorrectClick={() => {
                    trackEvent("Quiz - Clicked on Next", trackingPayload);
                    setActiveStep((q) => q + 1);
                    setQuestionStatus(null);
                  }}
                  onIncorrectClick={() => {
                    const isFirstAttempt = !activeQuestion.answers.some(
                      (answer) => hasAttemped[answer.id]
                    );
                    setHasAttempted((hasAttemped) => ({
                      ...hasAttemped,
                      [userAnswers[activeQuestion.id]]: true,
                    }));
                    if (
                      userAnswers[activeQuestion.id] ===
                      activeQuestion.correctAnswer.id
                    ) {
                      if (isFirstAttempt) {
                        setGrade((grade) => grade + 1);
                      }
                      setQuestionStatus("correct");
                      return;
                    }
                    setUserAnswers((answers) => {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      const { [activeQuestion.id]: _, ...rest } = answers;
                      return rest;
                    });
                    setQuestionStatus("incorrect");
                  }}
                />
              }
              backButton={
                <Button
                  variant="outlined"
                  onClick={() => {
                    setActiveStep((q) => q - 1);
                    trackEvent("Quiz - Clicked on Back", trackingPayload);
                  }}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </div>
        )}
        {activeStep >= questions.length && (
          <QuizGradesSection
            onMount={() => {
              (async () => {
                await session.setUserGrade({
                  quizId: quiz.id,
                  grade: grade / questions.length,
                  totalCorrectAnswers: grade,
                  totalQuestions: questions.length,
                });
              })();
            }}
            onClick={() => {
              onFinish();
            }}
            numQuestions={questions.length}
            correctAnswers={grade}
          />
        )}
      </Box>
    </Box>
  );
};

export default QuizView;
