import { Stack, Typography, useTheme } from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import TruckLottie from "src/assets/Lotties/Truck.json";
import Button from "src/components/Button";

type Props = {
  onStart: () => void;
  numQuestions: number;
};

const IntroSection = ({ onStart, numQuestions }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      spacing={theme.spacing(2)}
      padding={theme.spacing(4)}
      sx={{
        backgroundColor: theme.palette.primary.dark,
        minHeight: "100vh",
      }}
    >
      <div>
        <Typography
          color="white"
          textAlign="center"
          fontWeight="bold"
          variant="h4"
        >
          📚 {t("Ready to practice?")} 📚
        </Typography>
        <Typography textAlign="center" color="white">
          {t("Okay, show us what you got!")}
        </Typography>
      </div>
      <Typography color="white" textAlign="center" fontWeight="bold">
        {numQuestions} {t("questions")}
      </Typography>
      <Button onClick={onStart} variant="contained" size="large">
        {t("Start")}
      </Button>
      <Lottie animationData={TruckLottie} style={{ height: 350 }} />
    </Stack>
  );
};

export default IntroSection;
