import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Clients } from "src/contexts/AppContext";
import {
  GetQuizDocument,
  GetQuizQuery,
} from "src/pages/QuizPage/GetQuiz.generated";
import {
  GetResourceDocument,
  GetResourceQuery,
} from "src/pages/ResourceScreen/GetResource.generated";
import { UserAccount } from "src/types/User";
import fetchCurrentTheoryTrainingModule, {
  CurrentTheoryTrainingModule,
} from "./fetchCurrentTheoryTrainingModule";
import useErrorHandler from "./useErrorHandler";

type NextItemToDo = {
  type: "lesson" | "exam";
  id: string;
  link: string;
  resource: GetResourceQuery["resource"] | GetQuizQuery["quiz"];
} | null;

type ReturnDataType =
  | (NonNullable<CurrentTheoryTrainingModule> & {
      nextItemToDo: NextItemToDo;
    })
  | null;

export default function useCurrentTheoryTrainingModule(
  user: Pick<UserAccount, "uid" | "theoryTrainingPlan">,
  clients: Clients
): {
  currentModuleData: ReturnDataType;
  loading: boolean;
} {
  const [currentModuleData, setCurrentModuleData] =
    useState<ReturnDataType>(null);
  const [loading, setLoading] = useState(true);
  const errorHandler = useErrorHandler();
  const [fetchResource] = useLazyQuery<GetResourceQuery>(GetResourceDocument, {
    onError: errorHandler,
  });
  const [fetchQuiz] = useLazyQuery<GetQuizQuery>(GetQuizDocument, {
    onError: errorHandler,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const moduleData = await fetchCurrentTheoryTrainingModule(
          user,
          clients
        );
        if (!moduleData) return;

        let nextItemToDo: NextItemToDo = null;

        if (moduleData.uncompletedElements.lessons.length > 0) {
          const lessonId = moduleData.uncompletedElements.lessons[0];
          const { data } = await fetchResource({
            variables: { resourceId: lessonId },
          });
          nextItemToDo = {
            type: "lesson",
            id: lessonId,
            link: `/resources/${lessonId}`,
            resource: data?.resource || null,
          };
        } else if (moduleData.uncompletedElements.exams.length > 0) {
          const examId = moduleData.uncompletedElements.exams[0];
          const { data } = await fetchQuiz({
            variables: { quizId: examId },
          });
          nextItemToDo = {
            type: "exam",
            id: examId,
            link: `/quizzes/${examId}`,
            resource: data?.quiz || null,
          };
        }

        setCurrentModuleData({
          ...moduleData,
          nextItemToDo,
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [user, clients, fetchResource, fetchQuiz, errorHandler]);

  return { currentModuleData, loading };
}
