import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";
import i18n from "src/i18n/i18nConfig";

export default function formatOptionalTimestamp(dateString?: string): string {
  if (!dateString) return "-";
  const date = new Date(dateString);

  // messages are sorted by created at
  const hoursSince = differenceInHours(new Date(), date);
  const daysSince = differenceInDays(new Date(), date);

  if (hoursSince < 24) {
    if (hoursSince <= 1) {
      const minutesAgo = differenceInMinutes(new Date(), date);
      if (minutesAgo === 0) return i18n.t("just now");
      if (minutesAgo === 1) return `${minutesAgo} ${i18n.t("minute ago")}`;
      return `${minutesAgo} ${i18n.t("minutes ago")}`;
    }
    return `${hoursSince} ${i18n.t("hours ago")}`;
  }

  if (daysSince < 2) {
    return i18n.t("Yesterday");
  }

  if (daysSince <= 7) {
    return `${daysSince} ${i18n.t("days ago")}`;
  }

  return format(date, "MMM d yyyy");
}
