import { InfoOutlined, RocketOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header20 } from "src/components/Typography";
import MilestoneType from "src/types/MilestoneType.enum";
import useErrorHandler from "src/utils/useErrorHandler";
import SingleMilestoneForm from "./SingleMilestoneForm";
import {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

type Props = {
  milestoneType: MilestoneType;
  onCreate: (data: StudentPlanMilestoneFormData) => Promise<void>;
};

export default function MilestoneScheduleCard({
  milestoneType,
  onCreate,
}: Props) {
  const { t } = useTranslation();
  const [isCreateMilestoneOPen, setIsCreateMilestoneOpen] = useState(false);
  const template = REQUIRED_MILESTONES.find((m) => m.type === milestoneType);
  const errorHandler = useErrorHandler();

  const handleCreate = async (data: StudentPlanMilestoneFormData) => {
    try {
      await onCreate(data);
      setIsCreateMilestoneOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  if (!template) return <div />;

  return (
    <Card>
      <CardContent>
        <Chip variant="outlined" label={t("Milestone Creation")} />
        <Header20 marginTop={1}>
          {template.createInstructions?.title || template.name}
        </Header20>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          onClick={() => setIsCreateMilestoneOpen(true)}
          startIcon={<RocketOutlined />}
        >
          {t("Get Started")}
        </Button>
        {!!template.createInstructions && (
          <Button
            startIcon={<InfoOutlined />}
            variant="outlined"
            onClick={() =>
              window.open(template.createInstructions!.link, "_blank")
            }
          >
            {t("Learn More")}
          </Button>
        )}
      </CardActions>
      <Dialog
        open={isCreateMilestoneOPen}
        onClose={() => setIsCreateMilestoneOpen(false)}
      >
        <DialogContent>
          <SingleMilestoneForm
            milestoneType={milestoneType}
            formType="create"
            onSubmit={handleCreate}
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
}
