import { CircularProgress, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { differenceInDays, format, isAfter } from "date-fns";
import React from "react"; // Import React to use `createElement`
import { StudentPlanMilestone } from "src/types/StudentPlan";

interface MilestoneColumnProps {
  field: string;
  headerName: string;
}

export default function createMilestoneColumnUtil({
  field,
  headerName,
}: MilestoneColumnProps): GridColDef {
  return {
    field,
    headerName,
    disableColumnMenu: true,
    valueGetter: (milestone?: StudentPlanMilestone | "loading") => {
      if (milestone === "loading") return "loading";
      if (!milestone) return undefined;
      if (milestone.completedAt)
        return {
          status: "completed",
          date: milestone.date,
        };
      return milestone.date
        ? {
            status: "pending",
            date: milestone.date,
          }
        : undefined;
    },

    renderCell: (params: {
      value?: "loading" | { status: "completed" | "pending"; date: string };
    }) => {
      if (params.value === "loading") {
        return React.createElement(CircularProgress, {
          size: 12,
          variant: "indeterminate",
        });
      }

      if (params.value?.status === "completed") {
        return React.createElement(
          Typography,
          {
            variant: "body2",
            color: "green",
            alignSelf: "center",
            margin: "auto",
            display: "flex",
          },
          format(new Date(params.value.date), "MMMM d, yyyy")
        );
      }

      if (params.value !== undefined) {
        const isOverdue = isAfter(new Date(), new Date(params.value.date));
        const isUpcoming =
          differenceInDays(new Date(params.value.date), new Date()) <= 7;

        return React.createElement(
          Typography,
          {
            variant: "body2",
            alignSelf: "center",
            display: "flex",
            margin: "auto",
            color: isOverdue
              ? "error"
              : isUpcoming
              ? "darkblue"
              : "text.secondary",
          },
          format(new Date(params.value.date), "MMMM d, yyyy")
        );
      }

      return React.createElement("span", null, "");
    },
    sortComparator: (
      a: { status: string; date: string } | undefined | "loading",
      b: { status: string; date: string } | undefined | "loading"
    ) => {
      if (!a || a === "loading" || !a.date) return -1; // Sort undefined dates last
      if (!b || b === "loading" || !b.date) return 1;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
  };
}
