import { SvgIconComponent } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VideoAskItem } from "src/pages/OnboardingScreen/videoAskIds.enum";

type Props = {
  icon: SvgIconComponent;
  title: string;
  description: string;
  item: VideoAskItem;
};
export default function VideoAskActionItemCard({
  icon: Icon,
  title,
  item,
  description,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 400, mx: "auto", mt: 3, textAlign: "center" }}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={2}>
          <Icon color="primary" fontSize="large" />
        </Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/interviews/${item.formId}`)}
        >
          {t("Start")}
        </Button>
      </CardActions>
    </Card>
  );
}
