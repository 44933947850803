import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FullscreenBox from "src/components/FullScreenBox";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createUserChatMessage from "src/firebase/createUserChatMessage";
import { useCurrentUser } from "src/SessionBoundary";
import useErrorHandler from "src/utils/useErrorHandler";
import { trackEvent } from "../OnboardingScreen/amplitude";
import ReactivationDialog from "./ReactivationDialog";

const DisabledReasonBody = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();

  const handleRejectionContact = async () => {
    try {
      trackEvent("Rejected Screen - Clicked on Contact");
      await createUserChatMessage(
        {
          text: "I was rejected from the application process for an incorrect reason. Can you help me fix this?",
          user,
        },
        clients
      );
      navigate("/coach");
    } catch (err) {
      errorHandler(err);
    }
  };

  switch (user.applicationStatus) {
    case "rejected":
      return (
        <Stack spacing={2}>
          <Typography>
            {user.applicationStatusDetails
              ? t(
                  "Unfortunately, we cannot move you forward in the application process for the following reason:"
                )
              : t(
                  "Unfortunately, based on the information you provided, we cannot move you forward in the application process at this time."
                )}
          </Typography>
          <Typography>{user.applicationStatusDetails}</Typography>
          <Typography marginTop={2}>
            {t("Is this a mistake? Please contact our team.")}
          </Typography>
          <Box>
            <Button
              variant="contained"
              size="large"
              onClick={handleRejectionContact}
            >
              {t("Get in touch")}
            </Button>
          </Box>
        </Stack>
      );

    case "withdrawn":
    case "inactive":
    case "dropped":
    default:
      return <ReactivationDialog />;
  }
};

export default function DisabledAccountScreen() {
  const { t } = useTranslation();
  const user = useCurrentUser();

  useEffect(() => {
    trackEvent("Disabled Account Screen - Viewed", {
      applicationStatus: user.applicationStatus,
    });
  }, [user.applicationStatus]);

  return (
    <FullscreenBox
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <Stack
        spacing={6}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: theme.spacing(2),
          marginTop: "100px",
        })}
      >
        <ScreenTitle sx={{ textAlign: "center" }}>
          {t("Welcome back, {{ firstName }}!", { firstName: user.firstName })}
        </ScreenTitle>
        <DisabledReasonBody />
      </Stack>
    </FullscreenBox>
  );
}
