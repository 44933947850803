const THUMBNAIL_ASSETS = [
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/IntroUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/AirBrakesUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/CombinationVehiclesUnit.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/SafetyUnitThumbnail.jpeg",
  "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/TransportationUnitThumbnail.jpeg",
];

export default function getRandomThumbnailImage(order: number) {
  return THUMBNAIL_ASSETS[order % THUMBNAIL_ASSETS.length];
}
