const answerMapping: { [key: string]: string } = {
  "for all the above reasons": "All answers are correct.",
  "any of the above could occur": "All answers are correct.",
  "all of the above are true": "All answers are correct.",
  "all of the above can work": "All answers are correct.",
  "all of the above might happen": "All answers are correct.",
  "does all of the above": "All answers are correct.",
  "do all of the above": "All answers are correct.",
  "all of the above are correct": "All answers are correct.",
  "both of the above are advantages": "All answers are correct.",
  "do both of the above": "All answers are correct.",
  "both of the above are required procedures": "All answers are correct.",
  "all of the above will work": "All answers are correct.",
  "either of the above": "All answers are correct.",
  "all of the above statements are correct": "All answers are correct.",
  "both of the above are correct": "All answers are correct.",
  "all the above": "All answers are correct.",
  "all of above are correct": "All answers are correct.",
  "all of the above simultaneously if possible": "All answers are correct.",
  "any of the above": "All answers are correct.",
  "all of the above are acceptable": "All answers are correct.",
  "any one of the above": "All answers are correct.",
  "none of the above": "None of the answers are correct.",
  "neither of the above": "None of the answers are correct.",
};

const normalizeString = (str: string): string =>
  str.trim().toLowerCase().replace(/\./g, "");

const formatQuizQuestionAnswer = (str: string): string => {
  const normalizedStr = normalizeString(str);
  return answerMapping[normalizedStr] || str;
};

export default formatQuizQuestionAnswer;
