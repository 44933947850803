import RocketOutlined from "@mui/icons-material/RocketOutlined";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "../OnboardingScreen/amplitude";

export default function AdditionalExamPracticeCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    trackEvent("Clicked on Additional Exam Practice");
    navigate("/quizzes");
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Box>
            <Chip
              icon={<RocketOutlined color="error" />}
              sx={{ backgroundColor: "#FFE5D9" }}
              label="Looking for More?"
            />
          </Box>
          <Typography variant="h5">{t("Additional Practice Exams")}</Typography>
          <Typography variant="body2" color="text.secondary">
            {t(
              "We have over 3,000 exam-like questions for you to get some additional practice."
            )}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={handleClick}>
          {t("Practice")}
        </Button>
      </CardActions>
    </Card>
  );
}
