import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import { GetResourceDocument } from "./GetResource.generated";
import ResourceView from "./ResourceView";

const ResourceScreen = () => {
  const { id: resourceId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();

  if (!resourceId)
    throw new Error(
      "You are trying to access the lesson page without specifying the lesson ID!"
    );

  const { data: resourceData, loading: resourceLoading } = useQuery(
    GetResourceDocument,
    { variables: { resourceId }, onError: errorHandler }
  );

  if (resourceLoading || !resourceData?.resource) return <AppSkeleton />;

  // TODO: add correct navigation back to unit? Or maybe not if this is ok?
  return (
    <ResourceView
      resource={resourceData.resource}
      onBack={() => navigate("/")}
    />
  );
};

export default ResourceScreen;
