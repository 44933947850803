import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  QuizQuestionAnswerRecord,
  QuizQuestionRecord,
} from "src/clients/schema";
import { PaddedLayout } from "src/components/Layout";
import { QuestionAnswerStatus } from "src/types/Quiz";
import shuffleArray from "src/utils/shuffleArray";
import QuizQuestionAnswerItem from "./QuizQuestionAnswerItem";

interface Props {
  quizQuestion: Pick<QuizQuestionRecord, "question" | "id"> & {
    correctAnswer: Pick<QuizQuestionAnswerRecord, "id">;
    answers: Pick<QuizQuestionAnswerRecord, "id" | "answer">[];
  };
  status: QuestionAnswerStatus;
  onSelectAnswer: (quizId: string, answerId: string) => void;
  selectedAnswerId: string | undefined;
  answersAttempted: {
    [answerId: string]: boolean;
  };
}

const QuizQuestionCard = ({
  quizQuestion,
  status,
  selectedAnswerId,
  onSelectAnswer,
  answersAttempted,
}: Props) => {
  const { t } = useTranslation();
  const shuffled = useMemo(
    () => shuffleArray(quizQuestion.answers),
    [quizQuestion.answers]
  );

  return (
    <Box>
      <PaddedLayout>
        <p>{quizQuestion.question}</p>
        <Typography fontWeight="bold">{t("Choose 1 answer:")}</Typography>
      </PaddedLayout>
      {shuffled.map((answer, index) => (
        <Box key={answer.id} sx={{ marginY: 4 }}>
          <QuizQuestionAnswerItem
            onSelect={() => {
              onSelectAnswer(quizQuestion.id, answer.id);
            }}
            isSelected={answer.id === selectedAnswerId}
            answer={answer.answer}
            index={index}
            isCorrectAnswer={answer.id === quizQuestion.correctAnswer.id}
            showKey={answersAttempted[answer.id] || status === "correct"}
          />
        </Box>
      ))}
    </Box>
  );
};

export default QuizQuestionCard;
