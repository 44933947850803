import { KeyboardArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QuestionAnswerStatus } from "src/types";

type Props = {
  questionStatus: QuestionAnswerStatus;
  onIncorrectClick: () => void;
  onCorrectClick: () => void;
  isDisabled?: boolean;
};

export default function QuizNextButton({
  questionStatus,
  onCorrectClick,
  onIncorrectClick,
  isDisabled,
}: Props) {
  const { t } = useTranslation();

  switch (questionStatus) {
    case null:
    case "incorrect":
      return (
        <Button
          variant="contained"
          onClick={onIncorrectClick}
          disabled={isDisabled}
        >
          {questionStatus === "incorrect" ? t("Check Again") : t("Check")}
          <KeyboardArrowRight />
        </Button>
      );
    case "correct":
      return (
        <Button
          variant="contained"
          onClick={onCorrectClick}
          disabled={isDisabled}
        >
          {t("Next")}
          <KeyboardArrowRight />
        </Button>
      );
    default:
      return <div />;
  }
}
