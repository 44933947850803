import * as amplitude from "@amplitude/analytics-browser";
import { ApolloProvider } from "@apollo/client";
import "@fontsource/material-icons";
import "@fontsource/noto-sans"; // Defaults to weight 400.
import "@fontsource/space-grotesk"; // Defaults to weight 400
import { Box, Card, CardContent } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { lazy, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import ResourceScreen from "src/pages/ResourceScreen";
import UnitPage from "src/pages/UnitPage";
import client from "./clients/contentful";
import BackHomeButton from "./components/Button/BackHomeButton";
import { PaddedLayout } from "./components/Layout";
import Template from "./components/Layout/Template";
import MenuLayout from "./components/Navigation/MenuLayout";
import Logo from "./logo.svg";
import CaseloadScreen from "./pages/Admin/CaseloadScreen/CaseloadScreen";
import AdminLayout from "./pages/AdminScreen/AdminLayout";
import AppSkeleton from "./pages/AppSkeleton";
import ConsentScreen from "./pages/BackgroundSurveyScreen/ConsentScreen";
import UserBackgroundSurveyScreen from "./pages/BackgroundSurveyScreen/UserBackgroundSurveyScreen";
import { ErrorFallback } from "./pages/Common/ErrorScreen";
import CourseTab from "./pages/DashboardPage/CourseTab";
import DashboardPage from "./pages/DashboardPage/DashboardScreen";
import ExamListScreen from "./pages/DashboardPage/ExamListScreen/ExamListScreen";
import ProfileScreen from "./pages/DashboardPage/ProfileScreen";
import ForgotPasswordScreen from "./pages/ForgotPasswordScreen";
import LoginScreen from "./pages/LoginScreen";
import NotFoundScreen from "./pages/NotFoundScreen";
import AutomatedConditionalAcceptanceScreen from "./pages/OnboardingScreen/AutomatedConditionalAcceptanceScreen";
import EligibilityScreen from "./pages/OnboardingScreen/EligibilityScreen";
import InterviewScreen from "./pages/OnboardingScreen/InterviewScreen";
import OnboardingScreen from "./pages/OnboardingScreen/OnboardingScreen";
import SuccessPlanOnboardingScreen from "./pages/OnboardingScreen/SuccessPlanOnboardingScreen";
import CoachChatScreen from "./pages/Participant/CoachChatScreen";
import DocumentScreen from "./pages/Participant/DocumentScreen";
import ReferenceScreen from "./pages/Participant/ReferenceScreen";
import SuccessPlanScreen from "./pages/Participant/SuccessPlanScreen";
import PartnerHomeScreen from "./pages/PartnerScreen/PartnerHomeScreen";
import PartnerLoginScreen from "./pages/PartnerScreen/PartnerLoginScreen";
import PartnerSignupScreen from "./pages/PartnerScreen/PartnerSignupScreen";
import OfflineSetupPage from "./pages/Setup/OfflineSetupPage";
import SignupScreen from "./pages/SignupScreen";
import AuthenticationLayout from "./pages/SignupScreen/AuthenticationLayout";
import BasicMenuLayout from "./pages/SignupScreen/BasicMenuLayout";
import SessionBoundary from "./SessionBoundary";
import SnackbarProvider from "./SnackbarProvider";
import { UserType } from "./types/User";
import { CLP_ID } from "./utils/constants";

const EPubReaderPage = lazy(() => import("src/pages/EPubReaderPage"));
const QuizPage = lazy(() => import("src/pages/QuizPage"));
const ClientScreen = lazy(() => import("src/pages/ClientScreen/ClientScreen"));
const AdminScreen = lazy(() => import("src/pages/AdminScreen/AdminScreen"));
const CohortScreen = lazy(
  () => import("src/pages/Admin/CohortScreen/CohortScreen")
);
const AdminTeamScreen = lazy(
  () => import("src/pages/Admin/AdminTeamScreen/AdminTeamScreen")
);
const SuperAdminScreen = lazy(
  () => import("src/pages/Admin/SuperAdminScreen/SuperAdminScreen")
);
const CohortSelectScreen = lazy(
  () => import("src/pages/Admin/CohortScreen/CohortSelectScreen")
);
Sentry.init({
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://4375f731cdb1477f8f0e533df18dfde3@o1268295.ingest.sentry.io/6657092"
      : "",
  integrations: [new BrowserTracing()],
});

if (
  process.env.REACT_APP_AMPLITUDE_API_KEY &&
  process.env.NODE_ENV === "production"
) {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

function App(): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ApolloProvider client={client}>
        <SnackbarProvider>
          <Box position="static">
            <Routes>
              <Route
                path="/login"
                element={
                  <AuthenticationLayout
                    corner={
                      <img
                        src={Logo}
                        alt="Emerge Career"
                        style={{ width: 300 }}
                      />
                    }
                  >
                    <LoginScreen />
                  </AuthenticationLayout>
                }
              />
              <Route path="/register" element={<SignupScreen />} />
              <Route
                path="/forgot-password"
                element={
                  <AuthenticationLayout
                    corner={
                      <img
                        src={Logo}
                        alt="Emerge Career"
                        style={{ width: 300 }}
                      />
                    }
                  >
                    <ForgotPasswordScreen />
                  </AuthenticationLayout>
                }
              />

              <Route
                path="/partners"
                element={
                  <Suspense fallback={<AppSkeleton />}>
                    <Outlet />
                  </Suspense>
                }
              >
                <Route path="dashboard" element={<PartnerHomeScreen />} />

                <Route path="register" element={<PartnerSignupScreen />} />

                <Route path="login" element={<PartnerLoginScreen />} />
              </Route>

              <Route
                path="/onboarding"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    // TODO: revisit this
                    onboardingRedirectRule="redirectToDashboardIfOnboarded"
                  >
                    <Outlet />
                  </SessionBoundary>
                }
              >
                <Route
                  index
                  element={
                    <BasicMenuLayout>
                      <PaddedLayout>
                        <OnboardingScreen />
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="survey"
                  element={
                    <BasicMenuLayout>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <Card>
                            <CardContent>
                              <UserBackgroundSurveyScreen />
                            </CardContent>
                          </Card>
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="consents"
                  element={
                    <BasicMenuLayout navigationDisabled>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <Card>
                            <CardContent>
                              <ConsentScreen />
                            </CardContent>
                          </Card>
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="references"
                  element={
                    <BasicMenuLayout navigationDisabled>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <BackHomeButton />
                          <ReferenceScreen onboarding />
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="documents"
                  element={
                    <BasicMenuLayout>
                      <BackHomeButton />
                      <DocumentScreen />
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="success-plan"
                  element={
                    <BasicMenuLayout navigationDisabled>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <SuccessPlanOnboardingScreen />
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
                <Route
                  path="finish"
                  element={
                    <BasicMenuLayout navigationDisabled>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <AutomatedConditionalAcceptanceScreen />
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  }
                />
              </Route>
              <Route
                path="success-plan"
                element={
                  <BasicMenuLayout>
                    <PaddedLayout>
                      <Box margin="auto" maxWidth={800}>
                        <BackHomeButton />
                        <SuccessPlanScreen />
                      </Box>
                    </PaddedLayout>
                  </BasicMenuLayout>
                }
              />
              <Route
                path="/courses/:id"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <CourseTab courseId={CLP_ID} />
                  </SessionBoundary>
                }
              />
              <Route
                path="/units/:id"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    // TODO: uncomment this
                    // onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <UnitPage />
                  </SessionBoundary>
                }
              />
              <Route
                path="/resources/:id"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    // TODO: uncomment this
                    // onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <Template>
                      <ResourceScreen />
                    </Template>
                  </SessionBoundary>
                }
              />
              <Route
                path="/quizzes"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <Template>
                      <ExamListScreen />
                    </Template>
                  </SessionBoundary>
                }
              />
              <Route
                path="/quizzes/:id"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    // TODO: uncomment this
                    // onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <Suspense fallback={<AppSkeleton />}>
                      <QuizPage />
                    </Suspense>
                  </SessionBoundary>
                }
              />
              <Route
                path="/coach"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    onboardingRedirectRule="allowInactivedUserAccountsAccess"
                  >
                    <BasicMenuLayout>
                      <PaddedLayout>
                        <Box margin="auto" maxWidth={800}>
                          <Card>
                            <CardContent>
                              <CoachChatScreen />
                            </CardContent>
                          </Card>
                        </Box>
                      </PaddedLayout>
                    </BasicMenuLayout>
                  </SessionBoundary>
                }
              />
              <Route
                path="/interviews/:id"
                element={
                  <SessionBoundary userType={UserType.User}>
                    <InterviewScreen />
                  </SessionBoundary>
                }
              />
              <Route
                path="/profile"
                element={
                  <SessionBoundary
                    userType={UserType.User}
                    // TODO: uncomment this
                    // onboardingRedirectRule="redirectToOnboardingIfRequired"
                  >
                    <BasicMenuLayout>
                      <ProfileScreen />
                    </BasicMenuLayout>
                  </SessionBoundary>
                }
              />
              <Route
                path="/books/:bookId"
                element={
                  <SessionBoundary onboardingRedirectRule="redirectToOnboardingIfRequired">
                    <Suspense fallback={<AppSkeleton />}>
                      <EPubReaderPage />
                    </Suspense>
                  </SessionBoundary>
                }
              />
              <Route
                path="/setup"
                element={
                  <SessionBoundary onboardingRedirectRule="redirectToOnboardingIfRequired">
                    <OfflineSetupPage />
                  </SessionBoundary>
                }
              />
              <Route
                path="eligibility"
                element={
                  <BasicMenuLayout>
                    <PaddedLayout>
                      <Box margin="auto" maxWidth={800}>
                        <BackHomeButton />
                        <Card>
                          <CardContent>
                            <EligibilityScreen />
                          </CardContent>
                        </Card>
                      </Box>
                    </PaddedLayout>
                  </BasicMenuLayout>
                }
              />
              <Route
                index
                path="/"
                element={
                  <SessionBoundary onboardingRedirectRule="redirectToOnboardingIfRequired">
                    <DashboardPage />
                  </SessionBoundary>
                }
              />
              <Route
                path="/admin"
                element={
                  <SessionBoundary userType={UserType.OrganizationStaff}>
                    <Suspense fallback={<AppSkeleton />}>
                      <Outlet />
                    </Suspense>
                  </SessionBoundary>
                }
              >
                <Route
                  index
                  element={
                    <MenuLayout currentTab="clients">
                      <AdminLayout>
                        <AdminScreen />
                      </AdminLayout>
                    </MenuLayout>
                  }
                />
                <Route
                  path="cohorts"
                  element={
                    <MenuLayout currentTab="cohorts">
                      <AdminLayout>
                        <CohortSelectScreen />
                      </AdminLayout>
                    </MenuLayout>
                  }
                />
                <Route
                  path="cohorts/:id"
                  element={
                    <MenuLayout currentTab="cohorts">
                      <CohortScreen />
                    </MenuLayout>
                  }
                />
                <Route
                  path="team"
                  element={
                    <MenuLayout currentTab="team">
                      <AdminLayout>
                        <AdminTeamScreen />
                      </AdminLayout>
                    </MenuLayout>
                  }
                />
                <Route
                  path="caseload"
                  element={
                    <MenuLayout currentTab="caseload">
                      <AdminLayout>
                        <CaseloadScreen />
                      </AdminLayout>
                    </MenuLayout>
                  }
                />

                <Route
                  path="super-admin"
                  element={
                    <MenuLayout currentTab="superadmin">
                      <AdminLayout>
                        <SuperAdminScreen />
                      </AdminLayout>
                    </MenuLayout>
                  }
                />
                <Route
                  path="clients/:id"
                  element={
                    <MenuLayout currentTab="clients">
                      <ClientScreen />
                    </MenuLayout>
                  }
                />
              </Route>
              <Route
                path="*"
                element={
                  <Template>
                    <NotFoundScreen />
                  </Template>
                }
              />
            </Routes>
          </Box>
        </SnackbarProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
